import React, { Component } from 'react';
import ContactMenu from "./ContactMenu";
import "../styles/contact.css";


const Contact = () => {
  return (
    <>
      <ContactMenu />
    </>
  );
};

export default Contact;
